import logo from './logo.svg';
import './App.css';
import bunny from './bunny.png';
import layer1 from './layer1.png';
import layer2 from './layer2.png';
import layer3 from './layer3.png';
import {useRef} from 'react';
import {Parallax, ParallaxLayer} from '@react-spring/parallax';

function App() {

  const ref = useRef();

  return (

    <Parallax pages={2} style= {{backgroundImage: "linear-gradient(#abdcff,#acdcfe)"}}>



          <ParallaxLayer offset={0} speed={0.1} factor={1} style={{ opacity: 1, top:"-30vh",left:"-5vh"}}>
          <div class="animation_layer_mountain parallax" id="layer4"></div>
          </ParallaxLayer>

          <ParallaxLayer offset={0} speed={0.3} factor={1} style={{ opacity: 1, top:"10vh",left:"-51vh"}}>
          <div class="animation_layer_mountain parallax" id="layer5"></div>
          </ParallaxLayer>

          <ParallaxLayer offset={0} speed={0.3} factor={1} style={{ opacity: 1, top:"20vh",left:"-35vh"}}>
          <div class="animation_layer_mountain parallax" id="layer1"></div>
          </ParallaxLayer>

          <ParallaxLayer offset={0} speed={0.5} factor={1} style={{ opacity: 1, top:"-10vh",left:"45vh"}}>
          <div class="animation_layer_mountain parallax" id="layer2"></div>
          </ParallaxLayer>

          <ParallaxLayer offset={0} speed={0.8} factor={1} style={{ opacity: 1, top:"30vh"}}>
          <div class="animation_layer_mountain parallax" id="layer3"></div>
          </ParallaxLayer>


          <ParallaxLayer
          offset={0}
          speed={1}
          factor={2}
>
            <div className="bunnieaster-page-header">
            <div className="App-logo" id="bunnieasterz_logo"/>
            <h1> JOIN NOW </h1>
            <div className="socialmedia-section">
            <a href="https://discord.gg/eV9hMheS" target="_blank" ><img className="socialmedia" src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6918e57475a843f59f_icon_clyde_black_RGB.svg"/></a>
            <a href="http://x.com/bunnieasterz" target="_blank"><img className="socialmedia" src="https://upload.wikimedia.org/wikipedia/commons/c/ce/X_logo_2023.svg" /></a>
            </div>

            </div>

          </ParallaxLayer>

          <ParallaxLayer offset={0.8} speed={0.4} factor={1} style={{ opacity: 1} }>
          <ParallaxLayer horizontal="true" offset={0.1} speed={0.8} factor={3} style={{ opacity: 1 }}>
          <div class="animation_layer parallax" id="cloud1"></div>
          </ParallaxLayer>
          </ParallaxLayer>

          <ParallaxLayer offset={0.9} speed={0.3} factor={1} style={{ opacity: 1}}>
          <ParallaxLayer horizontal="true" offset={0.1} speed={0.4} factor={3} style={{ opacity: 1 }}>
          <div class="animation_layer parallax" id="cloud2"></div>
          </ParallaxLayer>
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={0.6} factor={1} style={{ opacity: 1 }}>
          <ParallaxLayer horizontal="true" offset={0} speed={1} factor={3} style={{ opacity: 1 }}>
          <div class="animation_layer parallax" id="cloud3"></div>
          </ParallaxLayer>
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={0.6} factor={1} style={{ opacity: 1 }}>
          <ParallaxLayer horizontal="true" offset={0} speed={-0.5} factor={3} style={{ opacity: 1 }}>
          <div class="animation_layer parallax" id="cloud4"></div>
          </ParallaxLayer>
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={1}>

          <div className="bunnieaster-soon">
          <h1>COMING SOON!</h1>

          </div>


          </ParallaxLayer>



        </Parallax>

  );
}

export default App;
